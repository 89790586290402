<template>
  <div class="dizme_tm_all_wrap" data-magic-cursor="show">
    <MobileHeaderVue dark />
    <HeaderVue dark />
    <HomeComponent dark />
    <!-- ABOUT ME-->
    <AboutMeComponent dark />
    <!-- /ABOUT ME-->

    <!-- THE BOAT -->
    <TheBoatComponent dark />
    <!-- /THE BOAT -->

    <!-- COPYRIGHT -->
    <CopyrightComponent />
    <!-- /COPYRIGHT -->
    <ScrollTopVue />
  </div>
</template>

<script>
import HeaderVue from "@/components/layout/Header.vue";
import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
import ScrollTopVue from "@/components/layout/ScrollTop.vue";
import { activeSkillProgress } from "@/utilits";
import AboutMeComponent from "../components/AboutMeComponent.vue";

import CopyrightComponent from "../components/CopyrightComponent.vue";
import HomeComponent from "../components/HomeComponent.vue";
import TheBoatComponent from "../components/TheBoat.vue";
export default {
  name: "IndexViewDark",
  components: {
    HomeComponent,
    AboutMeComponent,
    TheBoatComponent,
    CopyrightComponent,
    ScrollTopVue,
    MobileHeaderVue,
    HeaderVue,
  },
  mounted() {
    window.addEventListener("scroll", activeSkillProgress);
    document.querySelector("body").classList.add("dark");
  },
};
</script>
