<template>
  <div class="dizme_tm_section" id="theBoat">
    <div class="dizme_tm_skills">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>&nbsp;</span>
          <h3>&nbsp;</h3>
          <p>&nbsp;</p>
        </div>
        <div class="wrapper">
          <div class="left">
            <div class="dizme_tm_main_title wow fadeInUp" data-wow-duration="1s" data-align="left">

              <h3>Boston Whaler</h3>
              <span>150 Montauk</span>
              <p>
                The classic Boston Whaler Montauk remains one of the most popular center console boats out there, thanks
                to practical design and rugged utility. But there’s more than initially meets the eye: Just below surface
                level, a shallow draft enables quick planing and a stable, dry ride.
              </p>
              <p>&nbsp;</p>
              <p>
                Above, the roomy center console layout
                features ample storage space for everyone’s gear. Go fishing, go wakeboarding or just simply go.
                The Montauk’s time-tested design, trademark unsinkability and a host of comfort-minded details make it
                all possible.
              </p>
              <p>&nbsp;</p>
              <p>
                Boston Whaler's 150 Montauk reflects over 60 years of heritage combining time-tested utility and classic
                lines with modern comfort, design and technology. Incredibly easy to operate, and easy to love, the 150
                Montauk excels for both fishing and watersports.
              </p>
            </div>
          </div>
          <div class="right">
            <img :src="`/img/skills/${dark ? 2 : 1}.png`" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheBoat",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
