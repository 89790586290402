<template>

  <div class="dizme_tm_section" id="aboutMe">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>&nbsp;</span>
        </div>
        <div class="dizme_tm_main_title" data-align="center"></div>
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/1.png`" alt="" />
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <h3>Hello, I'm Captain Dan</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                Whether you’re a fishing pro or it’s your first time out on the water, when you charter a fishing tour, you want to be certain that your charter fishing captain has your best interest in mind.
              </p>
              <p>
                There are many amazing charters around the world, and part of what makes them so great and worthy of <i>telling a tale</i> is the captain that puts the trip together.
              </p>
              <p>
                After all, the captain is there to do a lot more than just point out fish and tell you the difference between Redfish and Speckled Trout.
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>Check availability</span></a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutMeComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
